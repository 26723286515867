<template>
  <div class="main">
    <div class="template_info">
      <div class="template_row">
        <p class="name">当前模板：<span style="display:inline-block;vertical-align: bottom; width: calc(100% - 74px); text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{curTemplate.name}}</span></p>
        <div class="template-priview">
          <img :src="curTemplate.cover+'&imageView2/2/w/256/h/256'" class="picture" alt="" @click="chooseTemplate" title="更换模板"/>
          <div class="template_row template_priview-btn" v-rights="'athena:design:edit'">
              <span style="float:right;color:#409EFF;cursor:pointer;" title="更换模板" @click="chooseTemplate">
                <GIcon icon="icon-genghuan1" />更换模板
                </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form_box menu-form">
      <c-form :config="componentList" :content="formData" :formShow="true" @submit="saveForm"></c-form>
    </div>
    <MenuType ref="chooseTemplate" @submit="getTemplateInfo"></MenuType>
  </div>
</template>

<script>
  import MenuType from '../components/MenuType'
  import { mapGetters } from 'vuex'

  export default {
    name: 'MenuSetting',
    components: { MenuType },
    data () {
      return {
        curTemplate: {},
        formData: {}
      }
    },
    computed: {
      ...mapGetters(['userRights']),
      componentList () {
        let { components } = this.curTemplate
        return {
          'name': '设置',
          'desc': '您可以在此页面完成数据设置',
          'submitBtnShow': true,
          components
        }
      },
      templateInfo () {
        return this.$store.state.template.templateInfo
      }
    },
    created () {
      this.getTemplateInfo()
    },
    methods: {
      async getTemplateInfo () {
        const { data: response } = await this.$axios.get('/athena/menu/detail')
        this.curTemplate = response.template
        let dataConfig = response.data && response.data.dataConfig
        this.formData = dataConfig || {}
        this.$store.commit('setTemplateState', {
          type: 'templateInfo',
          data: response
        })
      },
      chooseTemplate () {
        if (this.userRights.get('athena:design:edit')) {
          this.$refs.chooseTemplate.show(this.data)
        }
      },
      async saveForm (data) {
        const { id: templateId } = this.curTemplate
        let param = {
          templateId,
          data: JSON.stringify(data.form)
        }
        const response = await this.$axios.post('/athena/menu/saveData', param)
        if (response.code === '0000') {
          this.$message.success('保存成功')
          this.getTemplateInfo()
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    height: 100%;
    border: 1px solid transparent;
    .template_info {
      float: left;
      width: 240px;
      padding: 10px;
      font-size: 14px;
      .template-priview{
        position: relative;
        .template_priview-btn{
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 12px;
        }
      }
      .template_row {
        margin-bottom: 4px;
        .name {
          color: #878a92;
          margin-bottom: 4px;
        }
        .picture {
          display: block;
          width: 100%;
          min-height: 50px;
          cursor: pointer;
          border: 1px solid #ccc;
        }
      }
    }
    .form_box {
      float: left;
      width: calc(100% - 240px);
      height: 100%;
      border-left: 1px solid #e5e9f2;
      padding: 20px 0 20px 20px;
      overflow: auto;
    }
    .empty_box {
      width: 300px;
      height: 100px;
      line-height: 100px;
      border: 1px solid #ccc;
      margin: 100px auto;
      text-align: center;
      cursor: pointer;
      font-size: 20px;
    }
  }
  .menu-form{
    position: relative;
    ::v-deep{
      &>div{
        height: calc(100% - 60px);
      }
      .c-form{
        height: 100%;
        overflow-y: auto;
        padding-bottom: 0;
        padding-right: 0;
        .el_real_form{
          padding-right: 20px;
        }
      }
      .submit_box{
        width: 100%;
        height: 60px;
        line-height: 60px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #fff;
        border-top: 1px #ccc solid;
        text-align: center;
        .el-button{
          width: 180px;
        }
      }
    }
  }
</style>
